// Smooth scroll to anchor links
$('a').click(function(e) {
    scrollToAnchor(this.href, e, 80);
});

function scrollToAnchor(href, event, offset) {
    if (href.length <= 1) {
        return;
    }

    var hashIndex = href.indexOf('#');

    if (hashIndex === -1) {
        return;
    }

    var id = href.substring(hashIndex + 1);

    if (!id) {
        return;
    }

    var $el = $('#' + id);

    if ($el.length === 0){
        return;
    }

    $('html, body').animate({
        scrollTop: $el.offset().top - (offset || 0)
    }, 300);

    if (event) {
        event.preventDefault();
        window.location.hash = id;
    }
}

window.scrollToAnchor = scrollToAnchor;

if (window.location.hash) {
    let target = window.location.hash;
    history.pushState("", document.title, window.location.pathname + window.location.search);
    setTimeout(function () {
        $('html,body').animate({
            scrollTop: $(target).offset().top - 80
        }, 300);
    }, 800)
}