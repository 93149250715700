const videoBlocks = document.querySelectorAll('section.image-video-block video');

if(videoBlocks.length > 0) {
    videoBlocks.forEach(function(video, index) {
        let fullVideo = video.getAttribute('data-full-src');
        let fullVideoMobile = video.getAttribute('data-mobile-src');
        let playIcon = video.parentElement.querySelector('h2');
        let overlay = video.parentElement.querySelector('.overlay');
        if(playIcon) {
            playIcon.addEventListener('click', function() {
                if(fullVideoMobile && window.innerWidth < 1024) {
                    video.setAttribute('src', fullVideoMobile);
                }
                else {
                    video.setAttribute('src', fullVideo);
                }
                video.setAttribute('controls', 'true');
                video.setAttribute("controlslist", "nodownload noplaybackrate noremoteplayback");
                video.setAttribute("disablepictureinpicture", true);
                playIcon.classList.add('hide');
                overlay.classList.add('hide');
            });
        }
    });
}