import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

let sections = [];
let previews = document.querySelectorAll('.recipe-previews .featured-wrapper');
previews.forEach(function(preview, index) {
    sections.push(preview);
});
let recipesList = document.querySelector('.recipes-list');
if(recipesList) {
    sections.push(recipesList);
}
    
if(sections && sections.length > 0) {
    $(window).scroll(function (e) {
        let scrollTop = $(e.target).scrollTop();
        let firstTop = $(sections[0]).offset().top;
        let lastTop = $(sections[sections.length - 1]).offset().top;

        sections.forEach(function(el, index) {
            var $section = $(el);
            var offsetTop = $section.offset().top;
            var height = $section.outerHeight();
            var $figure = $section.find('.parallax-container');

            if (scrollTop > firstTop && scrollTop <= lastTop) {
                var diff = scrollTop + height - offsetTop;

                $figure.addClass('fixed');
                if (index > 0) {
                    $figure.css('height', '' + Math.min(diff, height) + 'px');
                }

            } else {
                $figure.removeClass('fixed');
                $figure.css('height', '');
            }
        });
    });

    sections.forEach(function(section, index) {
        let heading = section.querySelector('h2');
        let recipeLink = gsap.utils.toArray(section.querySelector("a:not(.tease-link)"));
        let recipeTeases = gsap.utils.toArray(section.querySelectorAll("ul li"));
        let scrollStart = "bottom-=400";
        if (section.classList.contains('recipes-list')) {
            scrollStart = "bottom-=200";
        }
        let tl = gsap.timeline({ 
            scrollTrigger: {
                trigger: section,
                start: `top ${scrollStart}`,
            },
            defaults: { 
                duration: 0.5, ease: "power2.out" 
            }
        });
        tl.fromTo(heading,
            {
                opacity: 0,
                y: 20,
                fontVariationSettings: '"EXPO" 100',
            },
            {
                duration: 1,
                opacity: 1,
                y: 0,
                fontVariationSettings: '"EXPO" -50',
                ease: "power3.out",
                delay: 0.5
            }, 
        );
        if(recipeLink && recipeLink.length) {
            tl.fromTo(recipeLink,
                {
                    opacity: 0,
                    y: 50
                },
                {
                    duration: 1,
                    opacity: 1,
                    y: 0
                }
            );
        }
        if(recipeTeases && recipeTeases.length) {
            ScrollTrigger.batch(recipeTeases, {
                start: "top bottom-=100",
                onEnter: (batch) => {
                    gsap.to(batch, 
                        {
                            duration: 0.75,
                            opacity: 1,
                            y: 0,
                            stagger: 0.75
                        },
                    );
                },
            });
        }
    });
}