const heroFrontpage = document.querySelector('section.hero-frontpage');

if(heroFrontpage) {
    let heroHeight = heroFrontpage.offsetHeight;
    $(window).scroll(function (e) {
        let scrollTop = $(this).scrollTop();
        if(scrollTop > heroHeight) {
            heroFrontpage.style.position = 'absolute';
        }
        else {
            heroFrontpage.style.position = 'fixed';
        }
    });
}